import React, { useState } from 'react';
import { GamingItemsFilters } from '@components/Details/GamingItemsFilters';
import { Item } from '@models/Item';
import { GamingItemCard } from '@components/Details/GamingItemCard';
import { ItemsFilterProvider } from '@context/items-filters';
import { GamingItemsFilterTags } from '@components/Details/GamingItemsFilterTags';
import { useBreakPoints } from '@utils/breakpoints';

const items: Item[] = [
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', class: 'Drakon.io', element: 'Earth', agility: '58/122', intelligence: '28/120', hashrate: '38/22', price: '0.066999 WETH' },
];

export function GamingItems() {
  const { breakpoint } = useBreakPoints();
  const [openFilters, setOpenFilters] = useState(false);

  const openItemsFilter = () => setOpenFilters(true);
  const closeItemsFilter = () => setOpenFilters(false);
  const cancelFilter = () => {

  }

  return (
    <ItemsFilterProvider>
      <section className="bg-light-black pb-20">
        <div className="max-w-8xl mx-auto px-5">
          <div className="grid grid-cols-12 gap-6">
            <div className="bg-semi-black rounded-full flex col-start-0 col-span-12 xl:col-start-4 xl:col-span-6">
              <div className="text-white font-normal text-base py-4 w-1/2 text-center xl:font-bold xl:text-2xl gradient rounded-full">Listing</div>
              <div className="text-white font-normal text-base py-4 w-1/2 text-center xl:font-bold xl:text-2xl">Trading history</div>
            </div>
          </div>
          <div className="flex items-center justify-between py-4 xl:py-8">
            <span className="text-white font-bold text-2xl xl:text-5xl">Games items</span>
            <div className="flex my-2">
              {breakpoint === 'desktop' && (
                <>
                  <div className="input-suffix mr-3">
                    <input placeholder="Search"/>
                    <div className="icon-suffix">
                      <img  alt="search" src="/assets/images/search-yellow.svg" />
                    </div>
                  </div>
                  <select className="mr-3">
                    <option>Currency</option>
                    <option>WETH</option>
                    <option>USDT</option>
                    <option>USDC</option>
                  </select>
                  <select className="mr-3">
                    <option>Recently Added</option>
                    <option>Lowest Price</option>
                    <option>Highest Price</option>
                  </select>
                </>
              )}
              <button className="btn">
                <img alt="compare" src="/assets/images/compare.svg" />
              </button>
            </div>
          </div>
          {breakpoint === 'mobile' && (
            <div className="flex pb-6">
              <div className="input-suffix mr-3 text-xs">
                <input placeholder="Search"/>
                <div className="icon-suffix">
                  <img  alt="search" src="/assets/images/search-yellow.svg" />
                </div>
              </div>
              <select className="mr-3 text-xs">
                <option>Currency</option>
                <option>WETH</option>
                <option>USDT</option>
                <option>USDC</option>
              </select>
              <select className="text-xs">
                <option>Recently Added</option>
                <option>Lowest Price</option>
                <option>Highest Price</option>
              </select>
            </div>
          )}
          <div className="grid grid-cols-6 gap-4 xl:grid-cols-12 xl:gap-6">
            {breakpoint === 'desktop' && (
              <div className="col-span-3 xl:block">
                <div className="filter-container rounded-xl">
                  <GamingItemsFilters />
                </div>
              </div>
            )}
            <div className="col-span-6 xl:col-span-9">
              <div className="flex items-center justify-between mb-6 xl:items-start">
                <div className="font-bold">
                  <span className="text-white">All search: </span>
                  <span className="text-yellow">2,263 items</span>
                </div>
                {breakpoint === 'desktop' && <GamingItemsFilterTags />}
                {breakpoint === 'mobile' && (
                  <button onClick={openItemsFilter}>
                    <img alt="filter" src="/assets/images/filter.svg" />
                  </button>
                )}
              </div>
              {breakpoint === 'mobile' && (
                <div className="mb-6">
                  <GamingItemsFilterTags />
                </div>
              )}
              <div className="grid grid-cols-2 gap-4 xl:grid-cols-9 xl:gap-6">
                {items.map((item, i) => (
                  <div className="col-span-1 xl:col-span-3" key={i}>
                    <GamingItemCard item={item} />
                  </div>
                ))}
              </div>
              <div className="flex justify-center pt-6">
                <div className="gradient p-0.5 rounded-xl">
                  <button className="bg-light-black px-6 py-1 rounded-xl xl:py-2.5">
                  <span className="text-gradient uppercase font-bold">
                    Load more
                  </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openFilters && breakpoint === 'mobile' && (
          <div className="absolute filter-container inset-0 z-40">
            <GamingItemsFilters />
            <div className="gap-4 grid grid-cols-2 px-5">
              <button className="btn text-white uppercase" onClick={closeItemsFilter}>Cancel</button>
              <button className="btn btn-primary text-white uppercase" onClick={closeItemsFilter}>Confirm</button>
            </div>
          </div>
        )}
      </section>
    </ItemsFilterProvider>
  )
}
