import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { FreeMode, Navigation, Thumbs } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/modules/free-mode/free-mode.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/thumbs/thumbs.scss';
import { useBreakPoints } from '@utils/breakpoints';

const images = [
  '/assets/images/game-image.jpg',
  '/assets/images/game-image.jpg',
  '/assets/images/game-image.jpg',
  '/assets/images/game-image.jpg',
  '/assets/images/game-image.jpg',
];

const thumbs = [
  '/assets/images/explore-1.jpg',
  '/assets/images/explore-2.jpg',
  '/assets/images/explore-3.jpg',
  '/assets/images/explore-1.jpg',
  '/assets/images/explore-2.jpg',
]

function ImageSwiper() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper
        modules={[Navigation, Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        loop
      >
        {images.map((image, i) => (
          <SwiperSlide key={i}>
            <div className="relative rounded-lg xl:rounded-3xl">
              <img className="object-cover rounded-lg w-full xl:rounded-3xl" alt="image" src={image}/>
              <div className="absolute inset-0 image-shadow rounded-3xl"/>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        modules={[FreeMode, Thumbs, Navigation]}
        onSwiper={setThumbsSwiper as any}
        spaceBetween={20}
        slidesPerView={5}
        freeMode
        loop
        watchSlidesProgress
        className="thumb-swiper"
      >
        {thumbs.map((thumb, i) => (
          <SwiperSlide key={i}>
            <button className="mt-8">
              <img className="object-cover rounded-lg w-full xl:rounded-3xl" alt="image" src={thumb}/>
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export function GamingDescription() {
  const { breakpoint } = useBreakPoints();

  return (
    <section className="bg-light-black py-12 xl:py-24">
      <div className="max-w-8xl mx-auto px-5 grid grid-cols-12 xl:gap-16">
        {breakpoint === 'desktop' && (
          <div className="col-span-7">
            <ImageSwiper />
          </div>
        )}
        <div className="col-span-12 xl:col-span-5">
          <img className="h-16 xl:h-auto" alt="logo" src="/assets/images/game-icon.png" />
          <div className="text-gray py-8">
            ChainGuardians is a unique universe where anime meets blockchain superhero. Guardians have been forming alliances, battling rivals and fighting for survival within a post apocalyptic, volatile and war-ridden universe.
          </div>
          <div className="text-white">
            Collect Your Own Piece of Blockchain History today!
          </div>
          <div className="grid grid-cols-3 w-full xl:flex xl:items-center mt-8 rounded-2xl xl:w-max gaming-summary">
            <div className="px-6 my-4 flex flex-col items-center xl:px-12 xl:my-8">
              <span className="text-white font-bold">10,000</span>
              <span className="text-gray text-sm">Items</span>
            </div>
            <div className="px-6 my-4 flex flex-col items-center xl:px-12 xl:my-8">
              <span className="text-white font-bold">2,050</span>
              <span className="text-gray text-sm">Available</span>
            </div>
            <div className="px-6 my-4 flex flex-col items-center xl:px-12 xl:my-8">
              <span className="text-white font-bold">2,000</span>
              <span className="text-gray text-sm">Owners</span>
            </div>
          </div>
        </div>
        {breakpoint === 'mobile' && (
          <div className="col-span-12 mt-6">
            <ImageSwiper />
          </div>
        )}
      </div>
    </section>
  )
}
