import React from 'react';
import { useItemsFilter } from '@context/items-filters';

export function GamingItemsFilterTags() {
  const [filters, setFilters] = useItemsFilter();

  const clearAll = () => setFilters({});
  const clearItem = (key: string) => () => setFilters({ ...filters, [key]: false });

  return (
    <div className="flex items-start justify-between">
      <div>
        {Object.entries(filters)
          .filter(([,value]) => value)
          .map(([key], i) => (
            <div key={i} className="btn btn-orange whitespace-nowrap mr-3 mb-2">
              {key}
              <button className="ml-2 w-4 h-4" onClick={clearItem(key)}>
                <img src="/assets/images/close.svg" />
              </button>
            </div>
          ))
        }
      </div>
      <button className="btn btn-text-orange whitespace-nowrap" onClick={clearAll}>
        Clear all
      </button>
    </div>
  )
}
