import React from 'react';
import { TopSellItem } from '@models/TopSell';

export function TopSellCard({ topSell }: { topSell: TopSellItem }) {
  return (
    <div className="bg-card-black rounded-2xl">
      <img className="rounded-t-2xl w-full" alt={topSell.name} src={topSell.imageUrl} />
      <div className="p-2 relative xl:p-4">
        <div className="absolute right-2 top-0 xl:right-6">
          <div className="relative">
            <img alt="rank" src="/assets/images/badge.svg" />
            <div className="absolute flex font-bold inset-0 justify-center text-card-black text-xl top-1">{topSell.rank}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img className="w-10 xl:w-auto" alt="icon" src="/assets/images/top-icon.png"/>
          <div className="ml-4 flex flex-col">
            <span className="text-white font-bold leading-6">{topSell.name}</span>
            <span className="text-yellow leading-6">{topSell.price}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
