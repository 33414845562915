import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';


export function Promotion() {
  return (
    <Swiper
      autoplay
      modules={[Pagination]}
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <div className="relative">
          <img className="h-48 object-cover xl:h-auto" alt="Promotion" src="/assets/images/promotion.jpg" />
          <div className="image-bottom-shadow absolute inset-0" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative">
          <img className="h-48 object-cover xl:h-auto" alt="Promotion" src="/assets/images/promotion.jpg" />
          <div className="image-bottom-shadow absolute inset-0" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative">
          <img className="h-48 object-cover xl:h-auto" alt="Promotion" src="/assets/images/promotion.jpg" />
          <div className="image-bottom-shadow absolute inset-0" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative">
          <img className="h-48 object-cover xl:h-auto" alt="Promotion" src="/assets/images/promotion.jpg" />
          <div className="image-bottom-shadow absolute inset-0" />
        </div>
      </SwiperSlide>
    </Swiper>
  )
}
