import React, { createContext, ReactNode, useContext, useState, } from 'react';
import { Dict } from '@models/Common';

const ItemsFilterContext = createContext<Dict<boolean>>({});
ItemsFilterContext.displayName = 'ItemsFilterContext';

type SetItemsFilterFunc = (value: Dict<boolean>) => void;
const SetItemsFilterContext = createContext<SetItemsFilterFunc>(() => {});
SetItemsFilterContext.displayName = 'SetItemsFilterContext';

export const useItemsFilter = (): [Dict<boolean>, SetItemsFilterFunc] => [
  useContext(ItemsFilterContext),
  useContext(SetItemsFilterContext)
];

export const ItemsFilterProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [filters, setFilters] = useState<Dict<boolean>>({});

  return (
    <SetItemsFilterContext.Provider value={setFilters}>
      <ItemsFilterContext.Provider value={filters}>
        {children}
      </ItemsFilterContext.Provider>
    </SetItemsFilterContext.Provider>
  )
}
