import React from 'react';
import { LiveAuction } from '@models/LiveAuction';
import { Link } from 'react-router-dom';

export function LiveAuctionCard({ live }: { live: LiveAuction }) {
  return (
    <div className="bg-card-black rounded-2xl">
      <div className="relative">
        <img className="rounded-t-2xl w-full" alt={live.name} src={live.imageUrl} />
        <button className="bg-card-black opacity-50 rounded-2xl px-2 py-1 absolute top-4 left-4">
          <img alt="cam" src="/assets/images/cam.svg" />
        </button>
        <div className="absolute bottom-0 left-0 right-0 top-1/2 shadow-image"/>
      </div>
      <div className="px-2 pt-2 xl:px-4 xl:pt-4">
        <div className="flex items-center justify-between">
          <span className="text-white font-bold">{live.name}</span>
          <div className="flex items-center justify-between">
            <img alt="like" src="/assets/images/like.png" />
            <span className="text-gray text-sm ml-2">{live.liked}</span>
          </div>
        </div>
        <div className="flex items-center justify-between pb-4">
          <div className="flex items-center justify-between">
            <img alt="class" src="/assets/images/class-dragon.png" />
            <span className="text-gray ml-2">{live.class}</span>
          </div>
        </div>
      </div>
      <div className="p-0 xl:px-4 xl:pb-4">
        <Link to="/details" className="bg-card-black-2 rounded-b-xl p-2 flex items-center justify-between w-full xl:p-4 xl:rounded-xl">
          <div className="flex flex-col">
            <span className="text-gray text-left text-xs">Current bid</span>
            <div className="flex items-center">
              <img alt="token" src="/assets/images/token.svg" />
              <span className="text-yellow font-bold ml-2">{live.price}</span>
            </div>
          </div>
          <div className="gap-2 grid grid-flow-col">
            <div className="bg-gray font-bold px-1 rounded-md text-white xl:px-2">{('0' + live.days).slice(-2)}</div>
            <div className="bg-gray font-bold px-1 rounded-md text-white xl:px-2">{('0' + live.hours).slice(-2)}</div>
            <div className="bg-gray font-bold px-1 rounded-md text-white xl:px-2">{('0' + live.minutes).slice(-2)}</div>
            <div className="bg-gray font-bold px-1 rounded-md text-white xl:px-2">{('0' + live.seconds).slice(-2)}</div>
          </div>
        </Link>
      </div>
    </div>
  )
}
