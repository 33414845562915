import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Link } from 'react-router-dom';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

const heros = [
  { name: 'Gamify', date: '2021-08-06 11:00 AM (UTC)', description: 'Meet VOX, the adorable collectible series from Gala Labs. Own, play and earn rewards with your unique ERC721 avatar.', backgroundUrl: '/assets/images/bg-home.jpg', imageUrl: '/assets/images/super-star.png' },
  { name: 'Gamify', date: '2021-08-06 11:00 AM (UTC)', description: 'Meet VOX, the adorable collectible series from Gala Labs. Own, play and earn rewards with your unique ERC721 avatar.', backgroundUrl: '/assets/images/bg-home.jpg', imageUrl: '/assets/images/super-star.png' },
  { name: 'Gamify', date: '2021-08-06 11:00 AM (UTC)', description: 'Meet VOX, the adorable collectible series from Gala Labs. Own, play and earn rewards with your unique ERC721 avatar.', backgroundUrl: '/assets/images/bg-home.jpg', imageUrl: '/assets/images/super-star.png' },
  { name: 'Gamify', date: '2021-08-06 11:00 AM (UTC)', description: 'Meet VOX, the adorable collectible series from Gala Labs. Own, play and earn rewards with your unique ERC721 avatar.', backgroundUrl: '/assets/images/bg-home.jpg', imageUrl: '/assets/images/super-star.png' },
]

export function TopHero() {
  return (
    <section className="bg-light-black">
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
      >
        {heros.map((hero, i) => (
          <SwiperSlide key={i}>
            <div className="overflow-hidden relative">
              <img className="h-160 object-cover xl:h-auto xl:max-h-232 xl:w-full" alt="bg-home" src={hero.backgroundUrl} />
              <div className="shadow-left absolute left-0 top-0 bottom-0 right-1/2" />
              <img className="absolute left-0 right-0 top-0 xl:left-auto" alt="super-star" src={hero.imageUrl} />
              <div className="shadow-bottom absolute left-0 right-0 bottom-0 top-1/2" />
              <div className="absolute inset-0 max-w-8xl mx-auto px-5 flex items-end xl:items-center">
                <div className="w-full xl:w-1/3">
                  <div className="text-white font-bold text-5xl pb-6">{hero.name}</div>
                  <div className="text-yellow pb-3">{hero.date}</div>
                  <div className="text-white pb-5 xl:pb-10">{hero.description}</div>
                  <Link to="/details" className="btn btn-primary font-bold uppercase">View Detail</Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
