import React from 'react';
import { LiveAuctionCard } from './LiveAuctionCard';

export const lives = [
  { imageUrl: '/assets/images/auction.jpg', name: 'Drakon Green', class: 'Official Cryptomon', liked: 50, price: '0.066999 WETH', days: 0, hours: 2, minutes: 50, seconds: 30 },
  { imageUrl: '/assets/images/auction.jpg', name: 'Drakon Green', class: 'Official Cryptomon', liked: 50, price: '0.066999 WETH', days: 0, hours: 2, minutes: 50, seconds: 30 },
  { imageUrl: '/assets/images/auction.jpg', name: 'Drakon Green', class: 'Official Cryptomon', liked: 50, price: '0.066999 WETH', days: 0, hours: 2, minutes: 50, seconds: 30 },
  { imageUrl: '/assets/images/auction.jpg', name: 'Drakon Green', class: 'Official Cryptomon', liked: 50, price: '0.066999 WETH', days: 0, hours: 2, minutes: 50, seconds: 30 },
  { imageUrl: '/assets/images/auction.jpg', name: 'Drakon Green', class: 'Official Cryptomon', liked: 50, price: '0.066999 WETH', days: 0, hours: 2, minutes: 50, seconds: 30 },
  { imageUrl: '/assets/images/auction.jpg', name: 'Drakon Green', class: 'Official Cryptomon', liked: 50, price: '0.066999 WETH', days: 0, hours: 2, minutes: 50, seconds: 30 },
]

export function LiveAuction() {
  return (
    <section className="bg-light-black py-14">
      <div className="max-w-8xl mx-auto">
        <div className="flex items-center justify-between pb-11 px-5">
          <div className="grid grid-flow-col gap-4">
            <img alt="Live Auction" src="/assets/images/live.svg" />
            <span className="text-white font-bold text-2xl flex items-center xl:text-5xl">Live Auction</span>
          </div>
          <div className="flex items-center">
            <div className="gradient p-0.5 rounded-xl">
              <button className="bg-light-black px-6 py-1 rounded-xl xl:py-2.5">
              <span className="text-gradient uppercase font-bold">
                View All
              </span>
              </button>
            </div>
          </div>
        </div>
        <div className="grid live-auction-card gap-6 px-5 overflow-auto">
          {lives.map((live, i) => <LiveAuctionCard key={i} live={live} />)}
        </div>
      </div>
    </section>
  )
}
