import React, { useState } from 'react';
import { ExploreItem } from '@models/Explore';
import { ExploreCard } from './ExploreCard';
import classnames from 'classnames';

const explores: ExploreItem[] = [
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-2.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-3.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' },
  { imageUrl: '/assets/images/explore-1.jpg', name: 'Drakon Green', liked: 50, class: 'Official Cryptomon', price: '0.066999 WETH' }
];

enum FILTERS {
  ALL,
  DIGITAL_ART,
  ANIME,
  E_SPORT,
  MUSIC,
  FILM,
  GAMING
}

enum CATEGORIES {
  ALL,
  TWO_D_DIGITAL,
  THREE_D_DIGITAL,
  PIXEL_ART,
  DIGITAL_PHOTOGRAPHY,
  PHOTOBASING
}

export function Explore() {

  const [filter, setFilter] = useState(FILTERS.ALL);
  const [category, setCategory] = useState(CATEGORIES.ALL);

  const onChangeFilter = (fil: FILTERS) => () => setFilter(fil);
  const onChangeCategory = (cate: CATEGORIES) => () => setCategory(cate);

  return (
    <section className="bg-light-black py-14 xl:py-28">
      <div className="max-w-8xl mx-auto px-5">
        <div className="flex items-center justify-between pb-11">
          <div className="grid grid-flow-col gap-4">
            <img alt="Explore" src="/assets/images/frame.svg" />
            <span className="text-white font-bold text-2xl flex items-center xl:text-5xl">Explore</span>
          </div>
          <div className="flex items-center justify-end">
            <div className="mr-4 hidden xl:grid xl:grid-flow-col xl:gap-2">
              <button className={classnames('btn uppercase', { 'btn-primary': filter === FILTERS.ALL })} onClick={onChangeFilter(FILTERS.ALL)}>All</button>
              <button className={classnames('btn', { 'btn-primary': filter === FILTERS.DIGITAL_ART })} onClick={onChangeFilter(FILTERS.DIGITAL_ART)}>Digital Art</button>
              <button className={classnames('btn', { 'btn-primary': filter === FILTERS.ANIME })} onClick={onChangeFilter(FILTERS.ANIME)}>Anime/Animation</button>
              <button className={classnames('btn', { 'btn-primary': filter === FILTERS.E_SPORT })} onClick={onChangeFilter(FILTERS.E_SPORT)}>E-Sport</button>
              <button className={classnames('btn', { 'btn-primary': filter === FILTERS.MUSIC })} onClick={onChangeFilter(FILTERS.MUSIC)}>Music</button>
              <button className={classnames('btn', { 'btn-primary': filter === FILTERS.FILM })} onClick={onChangeFilter(FILTERS.FILM)}>Film/TV</button>
              <button className={classnames('btn', { 'btn-primary': filter === FILTERS.GAMING })} onClick={onChangeFilter(FILTERS.GAMING)}>Gaming</button>
            </div>
            <select className="block mr-2 xl:hidden w-4/12">
              <option>All</option>
              <option>Digital Art</option>
              <option>Anime/Animation</option>
              <option>E-Sport</option>
              <option>Music</option>
              <option>Film/TV</option>
              <option>Gaming</option>
            </select>
            <select>
              <option>Sort</option>
            </select>
          </div>
        </div>
        <div className="flex items-center justify-between pb-8">
          <div className="grid grid-flow-col gap-4 overflow-auto">
            <button className={classnames('filter-option whitespace-nowrap', { 'selected': category === CATEGORIES.ALL })} onClick={onChangeCategory(CATEGORIES.ALL)}>All</button>
            <button className={classnames('filter-option whitespace-nowrap', { 'selected': category === CATEGORIES.TWO_D_DIGITAL })} onClick={onChangeCategory(CATEGORIES.TWO_D_DIGITAL)}>2D Digital Painting</button>
            <button className={classnames('filter-option whitespace-nowrap', { 'selected': category === CATEGORIES.THREE_D_DIGITAL })} onClick={onChangeCategory(CATEGORIES.THREE_D_DIGITAL)}>3D Digital Painting & Sculpting</button>
            <button className={classnames('filter-option whitespace-nowrap', { 'selected': category === CATEGORIES.PIXEL_ART })} onClick={onChangeCategory(CATEGORIES.PIXEL_ART)}>Pixel Art</button>
            <button className={classnames('filter-option whitespace-nowrap', { 'selected': category === CATEGORIES.DIGITAL_PHOTOGRAPHY })} onClick={onChangeCategory(CATEGORIES.DIGITAL_PHOTOGRAPHY)}>Digital Photography</button>
            <button className={classnames('filter-option whitespace-nowrap', { 'selected': category === CATEGORIES.PHOTOBASING })} onClick={onChangeCategory(CATEGORIES.PHOTOBASING)}>Photobashing</button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 xl:grid-cols-4 xl:gap-6">
          {explores.map((e, i) => <ExploreCard key={i} explore={e} />)}
        </div>
      </div>
    </section>
  )
}

