import React from 'react';
import { ConnectSocial } from '@components/Footer/ConnectSocial';

export function Footer() {
  return (
    <footer>
      <div className="bg-newsletter">
        <div className="max-w-7xl px-5 mx-auto py-7 flex flex-col xl:items-center justify-between xl:flex-row xl:py-14">
          <div>
            <img className="image-sign-up" alt="newsletter" src="/assets/images/newsletter.png" />
            <div className="sign-up-newsletter">
              Sign up for the <br/> Gamify newsletter
            </div>
          </div>
          <div className="send-email">
            <span className="text-white text-xs leading-6">
              I would like to receive information about Gamify and the services and related offers by Gamify at regular intervals via e-mail. I would like to be informed about developments and news on new products and the company of Gamify. I can revoke my consent at any time with effect for the future. Further information can be found in our Privacy Policy.
            </span>
          </div>
        </div>
      </div>
      <div className="bg-light-black">
        <div className="bg-menu flex justify-between">
          <div />
          <div className="grid grid-flow-col gap-16 py-8 overflow-auto px-5 xl:px-0">
            <a href="#" className="text-white whitespace-nowrap">Explore</a>
            <a href="#" className="text-white whitespace-nowrap">Loot Box</a>
            <a href="#" className="text-white whitespace-nowrap">Launchpad</a>
            <a href="#" className="text-white whitespace-nowrap">Staking</a>
            <a href="#" className="text-white whitespace-nowrap">Game stores</a>
            <a href="#" className="text-white whitespace-nowrap">Community</a>
            <a href="#" className="text-white whitespace-nowrap">About us</a>
          </div>
          <div />
        </div>
        <div className="max-w-8xl px-5 mx-auto py-8 grid grid-cols-2 xl:flex xl:items-start xl:justify-between xl:py-12">
          <div className="col-span-2 h-full py-3 flex justify-center pb-12 xl:pb-0">
            <img alt="Gamify" src="/assets/images/logo.svg" />
          </div>
          <div className="grid gap-4">
            <label className="font-bold text-gradient text-2xl">Community</label>
            <a href="#" className="text-white text-sm">Suggest Feature</a>
            <a href="#" className="text-white text-sm">Help Center</a>
          </div>
          <div className="grid gap-4">
            <label className="font-bold text-gradient text-2xl">Legal</label>
            <a href="#" className="text-white text-sm">Privacy policy</a>
            <a href="#" className="text-white text-sm">Terms and conditions</a>
            <a href="#" className="text-white text-sm">Cookie policy</a>
          </div>
          <div className="col-span-2 pt-12 xl:pt-0">
            <ConnectSocial />
          </div>
        </div>
      </div>
    </footer>
  )
}
