import * as React from 'react';
import { Explore } from '@components/Home/Explore';
import { LiveAuction } from '@components/Home/LiveAuction';
import { Promotion } from '@components/Home/Promotion';
import { TopSell } from '@components/Home/TopSell';
import { TopHero } from '@components/Home/TopHero';

export function Home() {
  return (
    <>
      <TopHero />
      <TopSell />
      <LiveAuction />
      <Promotion />
      <Explore />
    </>
  )
}
