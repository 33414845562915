import React from 'react';
import { Item } from '@models/Item';

export function GamingItemCard({ item }: { item: Item }) {
  return (
    <div className="bg-card-black rounded-lg xl:rounded-2xl">
      <div className="relative">
        <img className="rounded-t-lg xl:rounded-t-2xl" alt={item.name} src={item.imageUrl} />
        <div className="absolute bottom-0 left-0 right-0 top-1/2 shadow-image"/>
      </div>
      <div className="p-2 xl:p-4">
        <div className="text-white font-bold">{item.name}</div>
        <div className="flex items-center">
          <img alt="class" src="/assets/images/class-dragon.png" />
          <span className="text-gray ml-2">{item.class}</span>
        </div>
        <div className="bg-card-black-2 rounded-lg p-4 my-3">
          <div className="flex items-center justify-between mb-3">
            <span className="text-gray text-xs">Element</span>
            <div className="flex items-center">
              <img alt="earth" src="/assets/images/element-earth.png" />
              <span className="text-white text-xs ml-2">{item.element}</span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <span className="text-gray text-xs">Agility</span>
            <span className="text-white text-xs">{item.agility}</span>
          </div>
          <div className="flex items-center justify-between mb-3">
            <span className="text-gray text-xs">Intelligence</span>
            <span className="text-white text-xs">{item.intelligence}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-gray text-xs">Hashrate</span>
            <span className="text-white text-xs">{item.hashrate}</span>
          </div>
        </div>
        <button className="flex items-center w-full">
          <img alt="token" src="/assets/images/token.svg" />
          <span className="text-yellow font-bold ml-2">{item.price}</span>
        </button>
      </div>
    </div>
  )
}
