import React from 'react';
import { GamingDescription } from '@components/Details/GamingDescription';
import { GamingItems } from '@components/Details/GamingItems';

export function Details() {
  return (
    <>
      <GamingDescription />
      <GamingItems />
    </>
  )
}
