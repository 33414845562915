import React from 'react';
import { ExploreItem } from '@models/Explore';
import { Link } from 'react-router-dom';

export function ExploreCard({ explore }: { explore: ExploreItem }) {
  return (
    <div className="bg-card-black rounded-2xl">
      <div className="relative">
        <img className="rounded-t-2xl" alt={explore.name} src={explore.imageUrl} />
        <button className="bg-card-black opacity-50 rounded-2xl px-2 py-1 absolute top-4 left-4">
          <img alt="cam" src="/assets/images/cam.svg" />
        </button>
        <div className="absolute bottom-0 left-0 right-0 top-1/2 shadow-image"/>
      </div>
      <div className="px-2 pt-2 xl:px-4 xl:pt-4">
        <div className="flex items-center justify-between">
          <span className="text-white font-bold">{explore.name}</span>
          <div className="flex items-center justify-between">
            <img alt="like" src="/assets/images/like.png" />
            <span className="text-gray text-sm ml-2">{explore.liked}</span>
          </div>
        </div>
        <div className="flex items-center justify-between pb-4">
          <div className="flex items-center justify-between">
            <img alt="class" src="/assets/images/class-dragon.png" />
            <span className="text-gray ml-2">{explore.class}</span>
          </div>
        </div>
      </div>
      <div className="p-0 xl:px-4 xl:pb-4">
        <Link to="/details" className="bg-card-black-2 rounded-b-xl p-2 flex items-center w-full xl:p-4 xl:rounded-xl">
          <img alt="token" src="/assets/images/token.svg" />
          <span className="text-yellow font-bold ml-2">{explore.price}</span>
        </Link>
      </div>
    </div>
  )
}
