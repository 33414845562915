import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useBreakPoints } from '@utils/breakpoints';
import { ConnectSocial } from '@components/Footer/ConnectSocial';

export function Header() {
  const [top, setTop] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);

  const { breakpoint } = useBreakPoints();

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  useEffect(() => {
    if (breakpoint === 'desktop') {
      closeMenuFullScreen();
    }
  }, [breakpoint]);

  const openMenuFullScreen = () => setOpenMenu(true);
  const closeMenuFullScreen = () => setOpenMenu(false);

  return (
    <>
      <header className={
        classnames('fixed w-full z-30 bg-black transition duration-300 ease-in-out', {
          'xl:opacity-90 blur shadow-lg': !top
        })}>
        <div className="max-w-screen-2xl mx-auto px-5">
          <div className="flex items-center justify-between h-16 xl:h-20">
            <div className="flex">
              {breakpoint === 'mobile' && <a onClick={openMenuFullScreen}><img className="mr-2" alt="menu" src="/assets/images/menu.svg" /></a>}
              <img className="w-28 xl:w-auto" alt="Gamify" src="/assets/images/logo.svg" />
            </div>
            <div className="hidden xl:flex items-center justify-between">
              <div className="grid grid-flow-col gap-8 mr-8">
                <a href="#" className="text-white">Explore</a>
                <a href="#" className="text-gradient font-bold">Game stores</a>
                <a href="#" className="text-white">Staking</a>
                <a href="#" className="text-white">Community</a>
                <a href="#" className="text-white">About us</a>
              </div>
              <button className="bg-semi-black p-2 rounded-md">
                <img alt="search" src="/assets/images/search.svg" />
              </button>
            </div>
            <div>
              <button className="btn btn-primary uppercase font-bold mr-2 xl:mr-4">
                Connect Wallet
              </button>
              <select className="uppercase font-bold">
                <option>
                  EN
                </option>
              </select>
            </div>
          </div>
        </div>
      </header>
      {openMenu && (
        <>
          <div className="absolute inset-0 z-40 menu-full-screen-bg-1" />
          <div className="absolute inset-0 z-50 menu-full-screen-bg-2 p-5">
            <div className="flex items-center justify-between">
              <img alt="logo" src="/assets/images/logo.svg" />
              <button className="bg-semi-black p-3 rounded-xl" onClick={closeMenuFullScreen}>
                <img alt="close" src="/assets/images/close.svg" />
              </button>
            </div>
            <div className="input-suffix mt-11 mb-8">
              <input placeholder="Search"/>
              <div className="icon-suffix">
                <img  alt="search" src="/assets/images/search-yellow.svg" />
              </div>
            </div>
            <div className="bg-semi-black rounded-xl px-5">
              <a href="#" className="py-5 border-b border-light-gray flex items-center justify-between">
                <span className="text-white text-base">Explore</span>
                <img alt="arrow" src="/assets/images/arrow-right.svg" />
              </a>
              <a href="#" className="py-5 border-b border-light-gray flex items-center justify-between">
                <span className="text-gradient font-bold text-base">Game stores</span>
                <img alt="arrow" src="/assets/images/arrow-right.svg" />
              </a>
              <a href="#" className="py-5 border-b border-light-gray flex items-center justify-between">
                <span className="text-white text-base">Staking</span>
                <img alt="arrow" src="/assets/images/arrow-right.svg" />
              </a>
              <a href="#" className="py-5 border-b border-light-gray flex items-center justify-between">
                <span className="text-white text-base">Community</span>
                <img alt="arrow" src="/assets/images/arrow-right.svg" />
              </a>
              <a href="#" className="py-5 flex items-center justify-between">
                <span className="text-white text-base">About us</span>
                <img alt="arrow" src="/assets/images/arrow-right.svg" />
              </a>
            </div>
            <ConnectSocial />
          </div>
        </>
      )}
    </>
  );
}
