import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { Header } from '@partials/Header';
import { Footer } from '@partials/Footer';
import { Home } from '@pages/Home';
import { Details } from '@pages/Details';

export function App() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative">
      <Header />

      <main className="flex-grow pt-16 xl:pt-20">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/details">
            <Details />
          </Route>
        </Switch>
      </main>

      <Footer />
    </div>
  );
}
