import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { TopSellCard } from './TopSellCard';
import { TopSellItem } from '@models/TopSell';
import { useBreakPoints } from '@utils/breakpoints';
import { Link } from 'react-router-dom';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

const topSeller: TopSellItem[] = [
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 1},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 2},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 3},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 4},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 5},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 6},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 7},
  { imageUrl: '/assets/images/top-sell.jpg', name: 'John Weak', price: '512 USDC', rank: 8},
]

export function TopSell() {
  const { breakpoint } = useBreakPoints();

  return (
    <section className="bg-light-black pt-14">
      <div className="max-w-8xl mx-auto px-5">
        <div className="flex items-center justify-between pb-11">
          <div className="grid grid-flow-col gap-4">
            <img alt="Live Auction" src="/assets/images/medal.svg" />
            <span className="text-white font-bold text-2xl flex items-center xl:text-5xl">Top Seller</span>
          </div>
          <div className="flex items-center">
            <select className="text-sm mr-2 xl:text-base xl:mr-4">
              <option>USDC</option>
            </select>
            <select className="text-sm w-24 xl:w-auto xl:text-base">
              <option>Last 1 day</option>
              <option>Last 7 days</option>
              <option>Last 30 days</option>
            </select>
          </div>
        </div>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          slidesPerView={breakpoint === 'mobile' ? 2 : 4}
          spaceBetween={breakpoint === 'mobile' ? 8 : 24}
          slidesPerGroup={breakpoint === 'mobile' ? 2 : 4}
        >
          {topSeller.map((seller, i) => (
            <SwiperSlide key={i}>
              <div className="mb-10">
                <Link to="/details">
                  <TopSellCard topSell={seller} />
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
