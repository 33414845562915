import React from 'react';
import Collapse, { Panel } from 'rc-collapse';
import CheckBox from 'rc-checkbox';
import classnames from 'classnames';
import { useItemsFilter } from '@context/items-filters';

enum FILTERS {
  STATUS,
  ARMY_RANK,
  UNIQUENESS,
  UNIQUENESS_2
}

const Status = [
  { label: 'Fixed Price' },
  { label: 'On Auction' },
  { label: 'New' },
  { label: 'Has Offers' },
]

const ArmyRanks = [
  { label: 'Lieutenant', score: 1000 },
  { label: 'Guardian', score: 900 },
  { label: 'Captain', score: 870 },
  { label: 'Officer', score: 100 },
  { label: 'Legendary', score: 100 },
];

const Uniqueness = [
  { label: 'Uncommon', score: 100 },
  { label: 'Legendary', score: 100 },
  { label: 'Rare', score: 100 },
  { label: 'Common', score: 100 },
];

const Uniqueness2 = [
  { label: 'Water', score: 100 },
  { label: 'Air', score: 100 },
  { label: 'Light', score: 100 },
  { label: 'Fire', score: 100 },
  { label: 'Sound', score: 100 },
  { label: 'Thunder', score: 100 },
  { label: 'Earth', score: 100 },
];

function CheckBoxItem ({ item }: { item: { label: string; score: number } }) {
  const [ filters, setFilters ] = useItemsFilter();

  const onChecked = (e: Event) => {
    const checked = (e.target as any).checked;
    setFilters({
      ...filters,
      [item.label]: checked
    });
  }

  return (
    <div className="flex items-center">
      <CheckBox className="mr-2" checked={filters[item.label]} onChange={onChecked}/>
      <div className="w-full flex items-center justify-between">
        <span className="text-white">{ item.label }</span>
        <span className="text-gray">{ item.score }</span>
      </div>
    </div>
  )
}

function CheckBoxItemNoScore ({ item }: { item: { label: string } }) {
  const [ filters, setFilters ] = useItemsFilter();

  const onChecked = (e: Event) => {
    const checked = (e.target as any).checked;
    setFilters({
      ...filters,
      [item.label]: checked
    });
  }
  return (
    <div>
      <CheckBox className="mr-2" checked={filters[item.label]} onChange={onChecked}/>
      <span className="text-white">{ item.label }</span>
    </div>
  )
}

export function GamingItemsFilters() {
  const [,setFilters] = useItemsFilter();
  const clearFilters = () => setFilters({});

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <span className="text-white font-bold text-2xl">Filter</span>
        <button className="text-orange" onClick={clearFilters}>Clear all</button>
      </div>
      <Collapse
        className="my-6"
        defaultActiveKey={[FILTERS.STATUS, FILTERS.ARMY_RANK, FILTERS.UNIQUENESS, FILTERS.UNIQUENESS_2]}
        expandIcon={(props: any) =>
          <img
            className={classnames('collapse-ic transform', { 'rotate-0': props.isActive, 'rotate-180': !props.isActive })}
            src="/assets/images/collapse-icon.svg"
          />
        }
      >
        <Panel header="Status" key={FILTERS.STATUS}>
          <div className="gap-2 grid grid-cols-2">
            {Status.map((status, i) => <CheckBoxItemNoScore item={status} key={i}/>)}
          </div>
        </Panel>
        <Panel header="Army Rank" key={FILTERS.ARMY_RANK}>
          <div className="gap-2 grid max-h-52 overflow-auto">
            {ArmyRanks.map((item, i) => <CheckBoxItem item={item} key={i} />)}
          </div>
        </Panel>
        <Panel header="Uniqueness" key={FILTERS.UNIQUENESS}>
          <div className="gap-2 grid max-h-52 overflow-auto">
            {Uniqueness.map((item, i) => <CheckBoxItem item={item} key={i} />)}
          </div>
        </Panel>
        <Panel header="Uniqueness" key={FILTERS.UNIQUENESS_2}>
          <div className="gap-2 grid max-h-52 overflow-auto">
            {Uniqueness2.map((item, i) => <CheckBoxItem item={item} key={i} />)}
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}
